<template>
    <a-modal
        title="详情"
        width='50%'
        :visible="visible"
        :destroyOnClose="true"
        @cancel="handleCancel"
        :footer="null"
    >
        <a-spin :spinning="confirmLoading">
            <a-row>
                <a-form layout="inline">
                    <a-col>
                        <!-- 第1行详情 -->
                        <a-row>
                            <a-col :md="24" :sm="24">
                                <a-form-item  label="标题">
                                    <span>{{details.title}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="24" :sm="24">
                                <a-form-item  label="描述">
                                    <span>{{details.describe}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第2行详情 -->
                        <a-row>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="发布状态">
                                    <span>{{details.releaseStatus==1?'草稿':'发布'}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="用户角色">
                                    <span>{{details.userType==1?'用户':'商家'}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第3行详情 -->
                        <a-row v-show="details.coverImage">
                            <a-col :md="24" :sm="24">
                                <a-form-item   label="视频封面">
                                    <img 
                                        style="width:70px; padding:3px;margin:3px;border:0.5px solid #ccc"
                                        :src="details.coverImage"
                                        @click="()=>{showBigImg=true}"
                                    >
                                    <!-- 点开大图 -->
                                    <a-modal 
                                        :visible="showBigImg"
                                        :footer="null" 
                                        @cancel="()=>{showBigImg=false}"
                                    >
                                        <img style="width: 100%;height:100%;padding:10px;" :src="details.coverImage" />
                                    </a-modal>
                                </a-form-item>
                            </a-col>
                        </a-row><br>
                        <!-- 第4行详情 -->
                        <a-row v-show="details.url">
                            <a-col :md="24" :sm="24">
                                <a-form-item   label="视频">
                                    <video :src="details.url"  style="width: 100px; height: 100px" controls />
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第5行详情 -->
                        <a-row v-if="details.goodsList && details.goodsList.length>0">
                            <a-divider>商品信息</a-divider>
                            <a-col :md="24" :sm="24">
                                <a-row>
                                    <a-col :md="24" :sm="24">
                                        <a-form-item  label="商品名称">
                                            <span>{{details.goodsList[0].name}}</span>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row>
                                    <a-col :md="12" :sm="24">
                                        <a-form-item  label="规格类型">
                                            <span>{{details.goodsList[0].speType==1?'单规格':'多规格'}}</span>
                                        </a-form-item>
                                    </a-col>
                                    <a-col :md="12" :sm="24">
                                        <a-form-item  label="价格">
                                            <span>{{details.goodsList[0].price}} 元</span>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row>
                                    <a-col :md="12" :sm="24">
                                        <a-form-item  label="商品链接">
                                            <span>{{details.goodsLinkIds}}</span>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-form>
            </a-row>
        </a-spin>
    </a-modal>
</template>

<script>
import { videoListDetail } from '@/api/modular/mallLiving/videoList'

export default {
    data(){
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            details:{},
            form: this.$form.createForm(this),
            showBigImg:false
        }
    },
    methods:{
        //初始化方法
        detail(record){
            this.visible = true
            this.confirmLoading = true
            //获取详情数据
            setTimeout(()=>{
                videoListDetail({id: record.id}).then((res)=>{
                    if(res.success){
                        this.details = res.data
                        this.confirmLoading = false
                    }
                })
            },100)
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.visible = false
            this.confirmLoading = false
            this.details = {}
        }
    }
}
</script>
//视频分类接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-02-9 11:15:50
 */

//视频列表分页查询
export function videoList(parameter){
    return axios({
        url: '/video/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
// //视频分类新增
// export function videoClassAdd(parameter){
//     return axios({
//         url: '/videoCategory/add',
//         method: 'post',
//         data: parameter 
//     })
// }
// //视频分类编辑
// export function videoClassEdit(parameter){
//     return axios({
//         url: '/videoCategory/edit',
//         method: 'post',
//         data: parameter
//     })
// }
//视频详情
export function videoListDetail(parameter){
    return axios({
        url: '/video/detail',
        method: 'post',
        params: parameter
    })
}
// //视频分类修改状态（启用 禁用 删除）
export function videoListDelete(parameter){
    return axios({
        url: '/video/delete',
        method: 'post',
        data: parameter
    })
}

//视频审核
export function videoAudit(parameter){
    return axios({
        url: '/video/audit',
        method: 'post',
        data: parameter
    })
}

/**
* 模块名:编辑视频
* 代码描述:
* 作者:陈莉莉
* 创建时间:2022-06-18 10:41:39
*/
export function editVideo(parameter){
    return axios({
        url: "/video/edit",
        method: "POST",
        data: parameter
    })
}

/**
* 模块名:
* 代码描述: 批量编辑视频
* 作者:陈莉莉
* 创建时间:2022-06-18 11:05:57
*/
export function batchEditVideo(parameter){
    return axios({
        url: "/video/editBatch",
        method: "post",
        data: parameter
    })
}